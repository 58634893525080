import React from "react";
import EventForm from "../forms/EventForm";

const EventFormPage = ()=>{
    return(
        <>
        <EventForm/>
        </>
    )
}

export default EventFormPage;
