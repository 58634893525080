import React from 'react';
import './StarFieldAnimation.css'; // Import your CSS file

function StarFieldAnimation() {
  return (
    <div className="main2" style={{position:"relative", zIndex:"0"}}>
    </div>
  );
}

export default StarFieldAnimation;