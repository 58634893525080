import React from 'react'
import './Line.css'

export const Line = () => {
  return (
    <div className="lineparent">
    <div className="line"/>
  </div>
  )
}
